import axios from "axios";

export const headers = {
	"Tenant-Access-Token": "ZTlkNTc1ODg1NjQyYmNkYWEzMzUxY2FlOGU1ZjMzMDY=",
	"Tenant-Access-Key": "qHLOK7yJ43Ta1A",
};

export const api = axios.create({
	baseURL: "https://tiltlabs-dev.confirmu.com",
	headers,
});

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";
